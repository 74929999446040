import React from 'react'
import Slider from 'react-slick'
import _ from 'lodash'
import * as styles from './slider-details-room-styles.module.scss'

const SliderDetailsRooms = ({
  images,
}) => {
  const settings = {
    autoplay: false,
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    variableWidth: true,
    centerPadding: '60px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          // centerPadding: '60px',
          // centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '12%',
          dots: false,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '12%',
          dots: false,
          swipeToSlide: true,
        },
      },
    ],
  }
  return (
    <div className={styles.sliderRoom__container}>
      <div className={styles.sliderRoom__box}>
        <Slider
          {...settings}>
          {_.map(images, (img) => (
            <div className={styles.sliderRoom__card} key={img.url}>
              <img src={img.url} alt={img.alt} />
            </div>
          ))}
        </Slider>

      </div>
    </div>
  )
}

export default SliderDetailsRooms
