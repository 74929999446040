// extracted by mini-css-extract-plugin
export var active = "slider-details-room-styles-module--active--da458";
export var arrow = "slider-details-room-styles-module--arrow--1226a";
export var arrowDisabled = "slider-details-room-styles-module--arrow--disabled--bfe0d";
export var arrowLeft = "slider-details-room-styles-module--arrow--left--94d6a";
export var arrowRight = "slider-details-room-styles-module--arrow--right--94cd0";
export var blink = "slider-details-room-styles-module--blink--3bc38";
export var bookingBtn = "slider-details-room-styles-module--booking-btn--974fd";
export var bookingPageHeader = "slider-details-room-styles-module--booking-page-header--2e604";
export var checkout = "slider-details-room-styles-module--checkout--d2a1b";
export var commentsSlider = "slider-details-room-styles-module--commentsSlider--edc87";
export var dot = "slider-details-room-styles-module--dot--f0b42";
export var dots = "slider-details-room-styles-module--dots--17338";
export var equallyBound = "slider-details-room-styles-module--equallyBound--9ca64";
export var fader = "slider-details-room-styles-module--fader--624e4";
export var fader__slide = "slider-details-room-styles-module--fader__slide--a4bdb";
export var featuredSlider = "slider-details-room-styles-module--featuredSlider--0e194";
export var globalWrapper = "slider-details-room-styles-module--global-wrapper--3527e";
export var headerIcons = "slider-details-room-styles-module--header-icons--e3e0b";
export var homepage = "slider-details-room-styles-module--homepage--2f25c";
export var homepageReviews = "slider-details-room-styles-module--homepage-reviews--87356";
export var isOpenMenu = "slider-details-room-styles-module--is-open-menu--e1f0b";
export var keenSlider = "slider-details-room-styles-module--keen-slider--c0c16";
export var lineMove = "slider-details-room-styles-module--lineMove--96a50";
export var logo = "slider-details-room-styles-module--logo--d60fa";
export var navigationWrapper = "slider-details-room-styles-module--navigation-wrapper--90707";
export var scrollNav = "slider-details-room-styles-module--scrollNav--66426";
export var scrollNavWrap = "slider-details-room-styles-module--scrollNavWrap--f1019";
export var slickSlide = "slider-details-room-styles-module--slick-slide--20bff";
export var slickSlider = "slider-details-room-styles-module--slick-slider--d26f6";
export var sliderRoom__box = "slider-details-room-styles-module--sliderRoom__box--232c2";
export var sliderRoom__card = "slider-details-room-styles-module--sliderRoom__card--bda75";
export var sliderRoom__container = "slider-details-room-styles-module--sliderRoom__container--4ee89";
export var startTxtArea = "slider-details-room-styles-module--startTxtArea--703c1";