/* eslint-disable no-confusing-arrow */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import classNames from 'classnames'
// need to change later
import DropdownArrow from '../../../../assets/icons/reflections-icon-dropdown.svg'
import Minus from '../../../../assets/icons/reflections-group-icon-minus.svg'
import Plus from '../../../../assets/icons/reflections-group-icon-plus.svg'

import { device } from '../../../../theme/device'
import * as styles from './accordion-styles.module.scss'

const WrapperContent = styled.div`
  height: 0;
  width: 100%;
  background-color: transparent;
  color: black;
  font-size: 14px;
  text-align: center;
  position: relative;
  text-align: left;
  transition: all 200ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  
  ${(props) => props.isOpen
    && css`
      padding: 0 0 25px 0;
      margin-top: 0px;
      height: auto;
      transition: all 350ms cubic-bezier(0.08, 1.09, 0.32, 1.275);
    `};
  @media ${device.desktop} {
    // width: 420px;
  }
`
const TextContent = styled.div`
  padding: 6px 0 15px;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  line-height: 28px;
  font-size: 16px;
  font-family: 'Sofia Pro Regular';
  color: #595756;
  ${(props) => props.isOpen
    && css`
      visibility: visible;
      opacity: 1;
    `};
  @media ${device.tablet} {
    
  }
`

const sections = (accordionItems, click) => _.map(accordionItems, (i, idx) => {
  const arrowClass = classNames({
    [styles.accordion__arrow]: true,
    [styles.accordion__arrow__open]: i.open,
  })

  if (accordionItems.length === 0) return null
  return (
    <div className={styles.accordion__container} key={i.title + idx}>
      <div className={styles.accordion__box} >

        <p
          onClick={() => click(accordionItems.indexOf(i))}
          className={styles.accordion__header__title}>{i.title}</p>

        <div
          className={arrowClass}
          onClick={() => click(accordionItems.indexOf(i))}
        >
          {
            i.open ? <Minus width={30} /> : <Plus width={30} />
          }
        </div>

      </div>
      <WrapperContent WrapperContent isOpen={i.open}>
        {
          i.children && _.map(i.children, (child, index) => (

            <React.Fragment key={index}>

              <TextContent isOpen={i.open} >
                <div dangerouslySetInnerHTML={{ __html: child }} />
              </TextContent>
            </React.Fragment>

          ))
        }
      </WrapperContent>
    </div>
  )
})

const Accordion = ({ data }) => {
  const url = typeof window !== 'undefined' ? window.location.href : ''
  const [accordionItems, setAccordionItems] = useState([])
  useEffect(() => {
    const accordion = []
    data.forEach((i) => {
      accordion.push({
        title: i.title,
        children: i.children,
        isOpenByDefault: i.isOpenByDefault,
        open: i.open,
      })
    })
    setAccordionItems(accordion)
  }, [data, url])

  function click(i) {
    // const newAccordion = accordionItems.slice()
    if (accordionItems[i].open) {
      const newAccordion = accordionItems.map((accordionItem) => ({
        ...accordionItem,
        open: false,
      }))
      setAccordionItems(newAccordion)
    } else {
      const newAccordion = accordionItems.map((accordionItem) => ({
        ...accordionItem,
        open: !!accordionItem[i],
      }))
      newAccordion[i].open = !newAccordion[i].open
      setAccordionItems(newAccordion)
    }
  }

  return <div className={styles.accordion}>{sections(accordionItems, click)}</div>
}

export default Accordion
