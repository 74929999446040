// extracted by mini-css-extract-plugin
export var active = "suite-detail-styles-module--active--77719";
export var arrow = "suite-detail-styles-module--arrow--75e9d";
export var arrowDisabled = "suite-detail-styles-module--arrow--disabled--8c440";
export var arrowLeft = "suite-detail-styles-module--arrow--left--7eea2";
export var arrowRight = "suite-detail-styles-module--arrow--right--b7a03";
export var blink = "suite-detail-styles-module--blink--459d1";
export var bookingBtn = "suite-detail-styles-module--booking-btn--83573";
export var bookingPageHeader = "suite-detail-styles-module--booking-page-header--c8e70";
export var checkout = "suite-detail-styles-module--checkout--9f5e9";
export var commentsSlider = "suite-detail-styles-module--commentsSlider--41c06";
export var dot = "suite-detail-styles-module--dot--60619";
export var dots = "suite-detail-styles-module--dots--3f845";
export var equallyBound = "suite-detail-styles-module--equallyBound--097f7";
export var fader = "suite-detail-styles-module--fader--82cb3";
export var fader__slide = "suite-detail-styles-module--fader__slide--baba6";
export var featuredSlider = "suite-detail-styles-module--featuredSlider--34185";
export var globalWrapper = "suite-detail-styles-module--global-wrapper--f6c87";
export var headerIcons = "suite-detail-styles-module--header-icons--d55d1";
export var homepage = "suite-detail-styles-module--homepage--916b7";
export var homepageReviews = "suite-detail-styles-module--homepage-reviews--794b4";
export var isOpenMenu = "suite-detail-styles-module--is-open-menu--9904e";
export var keenSlider = "suite-detail-styles-module--keen-slider--06c5c";
export var lineMove = "suite-detail-styles-module--lineMove--66045";
export var logo = "suite-detail-styles-module--logo--02180";
export var navigationWrapper = "suite-detail-styles-module--navigation-wrapper--e8592";
export var scrollNav = "suite-detail-styles-module--scrollNav--35389";
export var scrollNavWrap = "suite-detail-styles-module--scrollNavWrap--cf205";
export var slickSlide = "suite-detail-styles-module--slick-slide--e7d22";
export var startTxtArea = "suite-detail-styles-module--startTxtArea--ff970";
export var suite__amenities = "suite-detail-styles-module--suite__amenities--615b9";
export var suite__amenities__content = "suite-detail-styles-module--suite__amenities__content--d68e0";
export var suite__arcordion = "suite-detail-styles-module--suite__arcordion--0e0c1";
export var suite__arcordion__box = "suite-detail-styles-module--suite__arcordion__box--f1fe9";
export var suite__box = "suite-detail-styles-module--suite__box--5063d";
export var suite__container = "suite-detail-styles-module--suite__container--97e3b";
export var suite__content__box = "suite-detail-styles-module--suite__content__box--11345";
export var suite__content__container = "suite-detail-styles-module--suite__content__container--5b30c";
export var suite__content__description = "suite-detail-styles-module--suite__content__description--d345c";
export var suite__content__text = "suite-detail-styles-module--suite__content__text--01927";
export var suite__footer = "suite-detail-styles-module--suite__footer--d47b5";
export var suite__header = "suite-detail-styles-module--suite__header--b1a0d";
export var suite__header__content = "suite-detail-styles-module--suite__header__content--f6e88";
export var suite__header__description = "suite-detail-styles-module--suite__header__description--a92fb";
export var suite__header__image = "suite-detail-styles-module--suite__header__image--d9314";
export var suite__header__subtitle = "suite-detail-styles-module--suite__header__subtitle--95404";
export var suite__highlights = "suite-detail-styles-module--suite__highlights--51ade";
export var suite__highlights__header = "suite-detail-styles-module--suite__highlights__header--1e541";
export var suite__highlights__section = "suite-detail-styles-module--suite__highlights__section--24b66";
export var suite__highlights__section__left = "suite-detail-styles-module--suite__highlights__section__left--1cf18";
export var suite__highlights__section__right = "suite-detail-styles-module--suite__highlights__section__right--02679";
export var suite__info__box = "suite-detail-styles-module--suite__info__box--5c1a0";
export var suite__info__content = "suite-detail-styles-module--suite__info__content--e422e";