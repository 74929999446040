// extracted by mini-css-extract-plugin
export var accordion = "accordion-styles-module--accordion--c8da8";
export var accordion__arrow = "accordion-styles-module--accordion__arrow--b4f40";
export var accordion__arrow__open = "accordion-styles-module--accordion__arrow__open--3a964";
export var accordion__body__content = "accordion-styles-module--accordion__body__content--ca80a";
export var accordion__box = "accordion-styles-module--accordion__box--f4239";
export var accordion__container = "accordion-styles-module--accordion__container--65108";
export var accordion__header__title = "accordion-styles-module--accordion__header__title--ca77f";
export var active = "accordion-styles-module--active--f828d";
export var arrow = "accordion-styles-module--arrow--09202";
export var arrowDisabled = "accordion-styles-module--arrow--disabled--e127e";
export var arrowLeft = "accordion-styles-module--arrow--left--6226a";
export var arrowRight = "accordion-styles-module--arrow--right--79b96";
export var blink = "accordion-styles-module--blink--adf6a";
export var bookingBtn = "accordion-styles-module--booking-btn--353b4";
export var bookingPageHeader = "accordion-styles-module--booking-page-header--085dd";
export var checkout = "accordion-styles-module--checkout--dcf5d";
export var commentsSlider = "accordion-styles-module--commentsSlider--a2fa2";
export var dot = "accordion-styles-module--dot--b62af";
export var dots = "accordion-styles-module--dots--89583";
export var equallyBound = "accordion-styles-module--equallyBound--fef69";
export var fader = "accordion-styles-module--fader--db77b";
export var fader__slide = "accordion-styles-module--fader__slide--025ae";
export var featuredSlider = "accordion-styles-module--featuredSlider--2313d";
export var globalWrapper = "accordion-styles-module--global-wrapper--633c8";
export var headerIcons = "accordion-styles-module--header-icons--0c4aa";
export var homepage = "accordion-styles-module--homepage--94dea";
export var homepageReviews = "accordion-styles-module--homepage-reviews--cf8bb";
export var isOpenMenu = "accordion-styles-module--is-open-menu--cb953";
export var keenSlider = "accordion-styles-module--keen-slider--8e56f";
export var lineMove = "accordion-styles-module--lineMove--7d61a";
export var logo = "accordion-styles-module--logo--7b703";
export var navigationWrapper = "accordion-styles-module--navigation-wrapper--da038";
export var scrollNav = "accordion-styles-module--scrollNav--e7d82";
export var scrollNavWrap = "accordion-styles-module--scrollNavWrap--d2a9a";
export var slickSlide = "accordion-styles-module--slick-slide--3e519";
export var startTxtArea = "accordion-styles-module--startTxtArea--4cc02";