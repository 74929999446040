import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../components'
import * as styles from './show-rooms-images-styles.module.scss'

const ShowRoomsImages = ({ images }) => {
  const { t } = useTranslation()
  const [hasMore, setHasMore] = useState(false)
  const [imagesToShow, setImagesToShow] = useState([])
  const [allImage, setAllImages] = useState(images)

  useEffect(() => {
    if (allImage.length > 4) {
      setHasMore(true)
    }
  }, [images])

  const seeMoreimages = () => {
    if (allImage.length <= imagesToShow.length + 4) {
      setHasMore(false)
      setImagesToShow(allImage)
    } else {
      setImagesToShow(...imagesToShow, ...allImage.slice(imagesToShow.length, 4))
    }
  }

  useEffect(() => {
    setImagesToShow(allImage.slice(0, 4))
  }, [images])

  return (
    <div className={styles.showroom__container}>
      {
        _.map(imagesToShow, (img) => (
          <div className={styles.showroom__box__image} key={img.url}>
            <img src={img.url} alt={img.alt}/>
          </div>
        ))
      }
      {hasMore && (
        <div className={styles.showroom__button}>
          <Button
            onClick={seeMoreimages}
            active={true}>
            {t('suites.buttons.load')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default ShowRoomsImages
