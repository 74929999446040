import _ from 'lodash'
import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import SuiteView from './suite-view'
import { makeRequest } from '../../helpers'

const SuitesController = (props) => {
  const { params } = props
  const [roomData, setRoomData] = useState([])
  const [faqData, setFaqData] = useState([])

  const [loading, setLoading] = useState(false)
  const [loadingFaq, setLoadingFaq] = useState(false)
  const { t, i18n } = useTranslation()
  const getFAQData = useCallback(async () => {
    setLoadingFaq(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'pages',
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
        slug: 'faq',
      } : {
        slug: 'faq'
      },
    }).then((resp) => {
      const response = _.get(resp, '[0].acf.faq_group')
      const mapresp = _.map(response, (item, idx) => ({
        title: item.title,
        children: [item.description],
        isOpenByDefault: false,
        open: idx === 0,
      }))
      setFaqData(mapresp)
      setLoadingFaq(false)
    })
  }, [])

  const getSuiteData = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      custom: true,
      endPoint: 'getRoomDetail',
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
        slug: params['*'],
      } : {
        slug: params['*']
      },
    }).then((resp) => {
      setRoomData(resp)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    getSuiteData()
    getFAQData()
  }, [i18n.language])

  const viewProps = {
    data: roomData,
    loading,
    loadingFaq,
    faqData,
    t,
    i18n,
  }
  return (
    <SuiteView {...viewProps} />
  )
}

export default SuitesController
