import React from 'react'
import { Router } from '@reach/router'
import _ from 'lodash'
import SuiteTemplate from '../templates/suite'
import SuiteTemplates from '../templates/suites'

const SuitePage = (props) => {
  const path = _.get(props, 'pageContext.locale') === 'en' ? '/suite/' : `${_.get(props, 'pageContext.locale')}/suite/`
  return (
    <Router>
      <SuiteTemplates {...props} path={path} />
      <SuiteTemplate {...props} path={`${path}:slug`} />
      <SuiteTemplate {...props} path={`${path}:id`} />
    </Router>
  )
}

export default SuitePage
