// extracted by mini-css-extract-plugin
export var active = "show-rooms-images-styles-module--active--acaca";
export var arrow = "show-rooms-images-styles-module--arrow--28acc";
export var arrowDisabled = "show-rooms-images-styles-module--arrow--disabled--59a01";
export var arrowLeft = "show-rooms-images-styles-module--arrow--left--79a02";
export var arrowRight = "show-rooms-images-styles-module--arrow--right--e5a62";
export var blink = "show-rooms-images-styles-module--blink--712e4";
export var bookingBtn = "show-rooms-images-styles-module--booking-btn--403d5";
export var bookingPageHeader = "show-rooms-images-styles-module--booking-page-header--75917";
export var checkout = "show-rooms-images-styles-module--checkout--47cb4";
export var commentsSlider = "show-rooms-images-styles-module--commentsSlider--4cf0a";
export var dot = "show-rooms-images-styles-module--dot--b50ec";
export var dots = "show-rooms-images-styles-module--dots--cfd4b";
export var equallyBound = "show-rooms-images-styles-module--equallyBound--59b18";
export var fader = "show-rooms-images-styles-module--fader--0f457";
export var fader__slide = "show-rooms-images-styles-module--fader__slide--68ca7";
export var featuredSlider = "show-rooms-images-styles-module--featuredSlider--36776";
export var globalWrapper = "show-rooms-images-styles-module--global-wrapper--8a5da";
export var headerIcons = "show-rooms-images-styles-module--header-icons--525fc";
export var homepage = "show-rooms-images-styles-module--homepage--9ed6f";
export var homepageReviews = "show-rooms-images-styles-module--homepage-reviews--e5083";
export var isOpenMenu = "show-rooms-images-styles-module--is-open-menu--e127e";
export var keenSlider = "show-rooms-images-styles-module--keen-slider--ffb0b";
export var lineMove = "show-rooms-images-styles-module--lineMove--30949";
export var logo = "show-rooms-images-styles-module--logo--d4284";
export var navigationWrapper = "show-rooms-images-styles-module--navigation-wrapper--df304";
export var scrollNav = "show-rooms-images-styles-module--scrollNav--4b6a1";
export var scrollNavWrap = "show-rooms-images-styles-module--scrollNavWrap--8f31d";
export var showroom__box__image = "show-rooms-images-styles-module--showroom__box__image--b2556";
export var showroom__button = "show-rooms-images-styles-module--showroom__button--14648";
export var showroom__container = "show-rooms-images-styles-module--showroom__container--e3fa0";
export var slickSlide = "show-rooms-images-styles-module--slick-slide--0cb60";
export var startTxtArea = "show-rooms-images-styles-module--startTxtArea--d1fd2";